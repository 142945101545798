import * as Sentry from "@sentry/nextjs";

import { apiUrl } from "@/lib/urlHelper";

export const UserType = {
	STUDIO: "studio",
	MODEL: "model",
	SINGLE: "single",
};

let authData;
let lastToken;

/**
 * @param {string} token
 * @param {boolean} fake
 * @param {string} site
 */
export default async function Auth(token, fake = false, site = "msc") {
	if (authData && token === lastToken) {
		return authData;
	}
	try {
		const response = await fetch(`${apiUrl()}/auth/${site}`, {
			method: "POST",
			mode: "cors",
			headers: {
				"Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
			},
			body: JSON.stringify({
				token,
				fake,
			}),
		});
		if (response.status !== 200) {
			Sentry.captureException(`Error: ${response.status}, ${(await response.json())?.error}`);
			return null;
		}
		authData = (await response.json())?.data;
		lastToken = token;
	} catch (error) {
		authData = null;
		lastToken = null;
		Sentry.captureException(error);
	}

	return authData;
}
