import { Loader as LoaderStyle, LoaderRing, LoaderMiddle, LoaderSpin } from "@/styles/Blocks.module.scss";

export default function Loader() {
	return (
		<div className={LoaderStyle}>
			<div className={LoaderRing} />
			<div className={`${LoaderMiddle} ${LoaderSpin}`} />
		</div>
	);
}
