import { useCallback, useEffect, useRef, useState } from "react";

import Link from "next/link";
import { useRouter } from "next/router";
import { useEvent } from "react-use";

import useTranslation from "@/hooks/useTranslation";
import { consentBar, manageCookiesModal, consentBarButtons, consentBarText } from "@/styles/Modals.module.scss";

export default function CookieConsent({
	setConsentAccepted,
	setShowManageConsent,
	consentAccepted = [],
	showManageConsent = false,
}) {
	const router = useRouter();
	const [manageCookies, setManageCookies] = useState(showManageConsent);
	const [analyticalCookieToggled, setAnalyticalCookieToggled] = useState(consentAccepted?.includes("analytical"));
	const { _ } = useTranslation();

	const [analyticalDialogHeight, setAnalyticalDialogHeight] = useState();
	const analyticalDialogRef = useRef(null);
	const [essentialDialogHeight, setEssentialDialogHeight] = useState();
	const essentialDialogRef = useRef(null);

	const handleResize = useCallback(() => {
		setAnalyticalDialogHeight(analyticalDialogRef?.current?.scrollHeight);
		setEssentialDialogHeight(essentialDialogRef?.current?.scrollHeight);
	}, []);

	useEffect(() => {
		const handleRouteChange = () => {
			setManageCookies(false);
		};
		router.events.on("routeChangeStart", handleRouteChange);

		return () => {
			router.events.off("routeChangeStart", handleRouteChange);
		};
	}, [router]);

	useEffect(() => {
		setManageCookies(showManageConsent);
	}, [showManageConsent]);

	useEvent("resize", handleResize);

	useEffect(() => {
		if (manageCookies) {
			handleResize();
		}
	}, [handleResize, manageCookies]);

	const AcceptAllHandler = useCallback(() => {
		setConsentAccepted(["essential", "analytical"]);
		if (setShowManageConsent) {
			setShowManageConsent(false);
		}
	}, [setConsentAccepted, setShowManageConsent]);

	const RejectAllHandler = useCallback(() => {
		setConsentAccepted(["essential"]);
		if (setShowManageConsent) {
			setShowManageConsent(false);
		}
	}, [setConsentAccepted, setShowManageConsent]);

	const ManageHandler = useCallback(() => {
		setManageCookies(true);
	}, []);

	const AnalyticalChangeHandler = useCallback(
		(e) => {
			setAnalyticalCookieToggled(e.target.checked);
		},
		[setAnalyticalCookieToggled]
	);
	const ConfirmHandler = useCallback(() => {
		const consent = ["essential"];
		if (analyticalCookieToggled) {
			consent.push("analytical");
		}
		setConsentAccepted(consent);
		if (setShowManageConsent) {
			setShowManageConsent(false);
		}
	}, [analyticalCookieToggled, setConsentAccepted, setShowManageConsent]);

	return manageCookies ? (
		<div className={manageCookiesModal}>
			<h3>{_("Privacy Preference Center")}</h3>
			<p>
				{_(
					"We process user's data to deliver content or advertisements and measure the delivery of such content or advertisements, extract insights and generate reports to understand service usage; and/or accessing or storing information on devices for that purpose. Below you may read further about the purposes for which we process data, exercise your preferences for processing, and/or see our partners."
				)}{" "}
				<Link href="/cookie-policy" target="_blank" prefetch={false}>
					{_("Cookie Policy")}
				</Link>
				.
			</p>
			<p className="button-50">
				<button type="button" className="button" onClick={AcceptAllHandler}>
					{_("Accept all")}
				</button>
				<button type="button" className="button" onClick={RejectAllHandler}>
					{_("Reject all")}
				</button>
			</p>
			<h3>{_("Manage cookie preferences")}</h3>
			<div>
				<h4>
					<span>
						{_("Always Active")} <input type="checkbox" className="dialog-angle" id="essential-checkbox" />
						<label htmlFor="essential-checkbox" />
					</span>
					{_("Essential Cookies")}
				</h4>
				<p className="dialog" style={{ "--element-height": `${essentialDialogHeight}px` }} ref={essentialDialogRef}>
					{_(
						"These are cookies that are required for the operation of our website. We use, for example, these cookies to enable core functionalities of our website or to route traffic to the right servers on our network."
					)}
				</p>
			</div>
			<div>
				<h4>
					<span>
						<input
							type="checkbox"
							className="checkbox-slider"
							id="analytical-cookies-checkbox"
							onChange={AnalyticalChangeHandler}
							checked={analyticalCookieToggled}
						/>
						<label htmlFor="analytical-cookies-checkbox" />
						<input type="checkbox" className="dialog-angle" id="analytical-checkbox-angle" />
						<label htmlFor="analytical-checkbox-angle" />
					</span>
					{_("Analytical Cookies")}
				</h4>
				<p className="dialog" style={{ "--element-height": `${analyticalDialogHeight}px` }} ref={analyticalDialogRef}>
					{_(
						"We use these cookies to gain insight into how you use our site. This helps us to understand how you interact with our site, what is interesting for you and how we can improve our services, and enhance the overall user experience."
					)}
				</p>
			</div>
			<p className="button-100">
				<button type="button" className="button" onClick={ConfirmHandler}>
					{_("Confirm my choices")}
				</button>
			</p>
		</div>
	) : (
		<div className={consentBar}>
			<div className={consentBarText}>
				<h2>{_("We Respect Your Privacy")}</h2>
				<p>
					{_(
						"We use cookies and similar technologies, including third-party cookies, to improve your experience, analyze traffic and user activity. To learn more about our cookies, please read our"
					)}{" "}
					<Link href="/cookie-policy" target="_blank" prefetch={false}>
						{_("Cookie Policy")}
					</Link>
					.
				</p>
			</div>
			<div className={consentBarButtons}>
				<p>
					<button type="button" className="button" onClick={AcceptAllHandler}>
						{_("Accept All")}
					</button>
				</p>
				<p>
					<button type="button" className="button" onClick={RejectAllHandler}>
						{_("Reject All")}
					</button>
				</p>
				<p>
					<button type="button" role="link" className="link" onClick={ManageHandler}>
						{_("Manage cookie settings")}
					</button>
				</p>
			</div>
		</div>
	);
}
