import { useEffect, useMemo, useState } from "react";

import { useRouter } from "next/router";
import { useSessionStorage } from "react-use";

import useProduct from "@/hooks/useProduct";
import Auth from "@/lib/auth";
import { TEST_TOKEN } from "@/lib/commonConfig";
import { dataLayerPush, EVENT_AUTHENTICATION_FAILED, EVENT_AUTHENTICATION_SUCCESS } from "@/lib/gtm";

export default function useAuth() {
	const { query, isReady } = useRouter();
	const [authData, setAuthData] = useState();
	const [isLoading, setIsLoading] = useState(true);
	const [isError, setIsError] = useState(false);
	const [product, setProduct] = useProduct();
	const [token, setToken] = useSessionStorage("auth-token");
	const [isFake, setIsFake] = useSessionStorage("is-fake", false);
	const { user, studio, performers, errors } = authData || false;
	const hasOtherModels = useMemo(() => {
		return (
			Array.isArray(performers) &&
			!!performers?.filter((model) => {
				return !model.owned;
			}).length
		);
	}, [performers]);

	useEffect(() => {
		if (!isReady || token) {
			return;
		}

		if (query?.token) {
			setToken(query.token);
		} else {
			setIsLoading(false);
		}

		if (query?.fake) {
			setIsFake(true);
		}
	}, [query, isReady, token]);

	useEffect(() => {
		if (token) {
			(async () => {
				const result = await Auth(token, isFake);
				setAuthData(result);
				setIsLoading(false);
				setIsError(result === null);
			})();
		}
	}, [isFake, token]);

	useEffect(() => {
		if (isError) {
			dataLayerPush({ event_name: EVENT_AUTHENTICATION_FAILED });
			setToken(null);
		} else if (authData) {
			// eslint-disable-next-line camelcase
			const traffic_type =
				document?.location.hostname.includes("staging", 0) ||
				isFake ||
				token === TEST_TOKEN ||
				process.env.NEXT_PUBLIC_APP_ENV !== "prod" ||
				user?.isTestAccount
					? "internal"
					: undefined;

			// eslint-disable-next-line camelcase
			dataLayerPush({ event_name: EVENT_AUTHENTICATION_SUCCESS, account_type: user?.type, traffic_type });
		}
	}, [authData, isError, setToken, user?.type]);

	useEffect(() => {
		if (performers?.length) {
			setProduct(performers[0].product);
		} else if (studio?.product) {
			setProduct(studio.product);
		}
	}, [performers, studio]);

	return isLoading
		? { isLoading, product, setProduct }
		: {
				user,
				studio,
				performers,
				errors,
				isLoading,
				isError,
				hasOtherModels,
				token,
				product,
				setProduct,
				reset: () => {
					setAuthData(false);
					setToken(undefined);
				},
		  };
}
