const en = require("./translations.en.json");

const i18n = {
	translations: {
		en,
	},
	defaultLang: "en",
	useBrowserDefault: true,
	defaultValues: {
		oranum: {
			model: "broadcaster",
			studio: "agency",
			Model: "Broadcaster",
			Studio: "Agency",
			"a model": "a broadcaster",
			"a Model": "a Broadcaster",
			"a studio": "an agency",
			"a Studio": "an Agency",
		},
		livejasmin: {
			model: "model",
			studio: "studio",
			Model: "Model",
			Studio: "Studio",
			"a model": "a model",
			"a Model": "a Model",
			"a studio": "a studio",
			"a Studio": "a Studio",
		},
	},
};

module.exports = i18n;
