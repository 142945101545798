import "normalize.css";
import "@/styles/iconfont.css";
import "@/styles/globals.scss";
import { Roboto } from "@next/font/google";
import localFont from "@next/font/local";

import Layout from "@/pages/_mainLayout";

const iconfont = localFont({
	src: [
		{
			path: "../assets/iconfont/iconfont.woff2",
		},
	],
});

const roboto = Roboto({
	weight: ["400", "700"],
	subsets: ["latin", "latin-ext"],
	style: ["normal"],
});

function MyApp({ Component }) {
	return (
		<>
			{/* eslint-disable-next-line react/no-unknown-property */}
			<style jsx global>{`
				:root {
					--font-iconfont: ${iconfont.style.fontFamily};
					--font-roboto: ${roboto.style.fontFamily};
				}
			`}</style>
			<Layout Component={Component} />
		</>
	);
}

export default MyApp;
