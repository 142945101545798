import { useEffect } from "react";

import { useRouter } from "next/router";

import Footer from "@/components/Footer";
import Header from "@/components/Header";
import ProductLogo from "@/components/ProductLogo";
import useProduct from "@/hooks/useProduct";

export default function StandaloneLayout({ Component }) {
	const [product, setProduct] = useProduct();
	const { query } = useRouter();

	useEffect(() => {
		if (!product && ["oranum", "livejasmin"].includes(query?.product)) {
			setProduct(query.product);
		}
	}, [product, query.product]);

	return (
		<>
			<Header />
			<main className="main-content">
				<ProductLogo product={product} />
				<Component />
			</main>
			<Footer />
		</>
	);
}
