export const EVENT_AUTHENTICATION_SUCCESS = "authentication_success";
export const EVENT_CONTINUE_CLICK = "continue_click";
export const EVENT_CANCEL_PROCESS_CLICK = "cancel_process_click";
export const EVENT_ACCOUNT_SELECTION_CLICK = "account_selection_click";
export const EVENT_UNDERSTAND_CLICK = "understand_click";
export const EVENT_GIVE_UP_CLICK = "giveup_earning_click";
export const EVENT_AGREE_PROCEED_CLICK = "i_agree_and_want_to_proceed_click";
export const EVENT_SET_PAYMENT_METHOD_CLICK = "set_payment_method_click";
export const EVENT_SET_BILLING_CLICK = "set_billing_address_click";
export const EVENT_PROCESS_COMPLETED = "process_completed";
export const EVENT_PROCESS_REVOKED = "process_revoked";
export const EVENT_AUTHENTICATION_FAILED = "authentication_failed";
export const EVENT_DELETE_MY_ACCOUNT_CLICK = "delete_my_account_click";
export const EVENT_DELETE_MODEL_CLICK = "delete_model_under_my_studio_click";
export const EVENT_SUPPORT_CLICK = "contact_support_click";

export const PAYOUT_UNPAID_EARNINGS = "unpaid earnings";
export const PAYOUT_NO_PAYOUT_METHOD = "no payout method";
export const PAYOUT_NO_BILLING_ADDRESS = "no billing address";
export const PAYOUT_EARNINGS_BELOW_LIMIT = "earnings below 5 usd";

/**
 * @param {{account_type: *, event_name: string, total_accounts: int, source_page: string, dropdown_option_selected: string, traffic_type?: string}} props
 */
export const dataLayerPush = (props) => {
	window.dataLayer?.push({
		event: "GA - Event - Generic Event",
		...props,
	});
};
