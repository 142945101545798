// eslint-disable-next-line import/prefer-default-export

export const mscPayoutOptions = {
	oranum: `https://${process.env.mscHomes.oranum}/payout/options/details`,
	livejasmin: `https://${process.env.mscHomes.livejasmin}/payout/options/details`,
};

export const mscBillingAddress = {
	oranum: `https://${process.env.mscHomes.oranum}/payout/billing-address`,
	livejasmin: `https://${process.env.mscHomes.livejasmin}/payout/billing-address`,
};

export const mscSupportChat = {
	oranum: `https://${process.env.mscHomes.oranum}/online-support-chat/topic/other`,
	livejasmin: `https://${process.env.mscHomes.livejasmin}/online-support-chat/topic/other`,
};

export const mscPrivacyPolicy = {
	oranum: `https://${process.env.mscHomes.oranum}/privacy-policy`,
	livejasmin: `https://${process.env.mscHomes.livejasmin}/privacy-policy`,
};

export const mscHome = {
	oranum: `https://${process.env.mscHomes.oranum}`,
	livejasmin: `https://${process.env.mscHomes.livejasmin}`,
};

export const apiUrl = () => {
	if (document?.location.hostname.includes("staging", 0)) {
		return process.env.NEXT_PUBLIC_API_URL_STAGING;
	}
	return process.env.NEXT_PUBLIC_API_URL;
};
