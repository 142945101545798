import { useTranslation as useT } from "next-export-i18n";

import useProduct from "@/hooks/useProduct";

import { defaultValues } from "../i18n/index";

export default function useTranslation() {
	const { t } = useT();
	const [product] = useProduct();

	const currentProduct = product ?? "livejasmin";

	return {
		_: (key, view = {}) => {
			return t(key, { ...defaultValues[currentProduct], ...view });
		},
	};
}
