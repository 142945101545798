import Link from "next/link";

import useTranslation from "@/hooks/useTranslation";
import { footer as footerStyle, footerAddress, footerCompany, footerLinks } from "@/styles/Footer.module.scss";

export default function Footer() {
	const year = new Date().getUTCFullYear();
	const company = `JWS Americas S.à\u00A0r.l. and JWS International S.à\u00A0r.l.`;
	const { _ } = useTranslation();

	return (
		<footer className={footerStyle}>
			<p className={footerCompany}>
				&copy; {year} {company} - All rights reserved.
			</p>
			<p className={footerAddress}>
				44, Avenue John F. Kennedy, L-1855, Luxembourg City,
				<wbr /> Grand Duchy of Luxembourg
			</p>
			<ul className={footerLinks}>
				<li>
					<Link href="/privacy-policy" target="_blank" prefetch={false}>
						{_("Privacy Policy")}
					</Link>
				</li>
				<li>
					<Link href="/cookie-policy" target="_blank" prefetch={false}>
						{_("Cookie Policy")}
					</Link>
				</li>
			</ul>
		</footer>
	);
}
