import { useCallback, useState } from "react";

import Image from "next/image";
import Link from "next/link";

import CaretDown from "!svg-react-loader!@/assets/icons/caret-down.svg";
import CaretUp from "!svg-react-loader!@/assets/icons/caret-up.svg";
import UserIcon from "!svg-react-loader!@/assets/icons/user.svg";
import Logo from "@/assets/logos/jws-privacy-logo-white.svg";
import { ToolTipBottom, ToolTipShow } from "@/styles/Blocks.module.scss";
import { header as headerStyle, headerLogo, headerUser } from "@/styles/Header.module.scss";

export default function Header({ user }) {
	const [showUserTooltip, setShowUserTooltip] = useState(false);

	const handleUserClick = useCallback(() => {
		setShowUserTooltip(!showUserTooltip);
	}, [showUserTooltip]);

	return (
		<header className={headerStyle}>
			<Link href="/" passHref className={headerLogo}>
				<Image src={Logo} width={115} height={22} alt="JWS Privacy" />
			</Link>
			{user && (
				// eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
				<div className={`${headerUser} ${showUserTooltip && ToolTipShow}`} onClick={handleUserClick}>
					<UserIcon />
					<span>{user.email}</span>
					{showUserTooltip ? <CaretUp className="caret" /> : <CaretDown className="caret" />}
					<div className={ToolTipBottom}>{user.email}</div>
				</div>
			)}
		</header>
	);
}
