import * as Sentry from "@sentry/nextjs";

import { apiUrl } from "@/lib/urlHelper";

let consentData = null;
let lastToken;

/**
 * @param {string} token
 * @param {string[]} consent
 * @param {string} site
 */
export default async function Consent(token, consent, site = "msc") {
	if (consentData && (!consent || JSON.stringify(consentData) === JSON.stringify(consent)) && token === lastToken) {
		return consentData;
	}
	try {
		const response = await fetch(`${apiUrl()}/consent/${site}`, {
			method: "POST",
			mode: "cors",
			headers: {
				"Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
			},
			body: JSON.stringify({
				token,
				consent,
			}),
		});
		if (response.status !== 200) {
			Sentry.captureException(`Error: ${response.status}, ${(await response.json())?.error}`);
			return null;
		}
		consentData = (await response.json())?.data;
		lastToken = token;
	} catch (error) {
		consentData = null;
		lastToken = null;
		Sentry.captureException(error);
	}

	return consentData;
}
