import Image from "next/image";

import ImageLock from "@/assets/images/Lock.png";
import ImageLock2x from "@/assets/images/Lock@2x.png";
import useTranslation from "@/hooks/useTranslation";
import { genericErrorBox, PictureLock } from "@/styles/Error.module.scss";

export default function AuthenticationError() {
	const { _ } = useTranslation();

	return (
		<div className={genericErrorBox}>
			<picture className={PictureLock}>
				<source srcSet={`${ImageLock.src} 1x, ${ImageLock2x.src} 2x`} />
				<Image src={ImageLock} alt="Lock graphics" />
			</picture>
			<h1>{_("Authentifiction failed!")}</h1>
			<p>{_("Sorry, we couldn't log you in. Please close this page and try again.")}</p>
		</div>
	);
}
