import { useEffect, useState } from "react";

import { useRouter } from "next/router";
import { useSessionStorage } from "react-use";

import { Breadcrumbs as BreadcrumbsClass } from "@/styles/Blocks.module.scss";

export default function Breadcrumbs({ currentPage }) {
	const router = useRouter();
	const [isClient, setIsClient] = useState(false);
	const [currentBreadcrumbs, setCurrentBreadcrumbs] = useSessionStorage("breadcrumbs", ["Forget my data"]);

	useEffect(() => {
		setIsClient(true);
	}, []);

	useEffect(() => {
		let currentPageName = currentPage;
		const isRoot = currentPageName && currentPageName.indexOf("/") === 0;
		if (isRoot) {
			currentPageName = currentPageName.slice(1);
			if (currentBreadcrumbs.indexOf(currentPageName) !== 0) {
				setCurrentBreadcrumbs([currentPageName]);
				return;
			}
		}
		if (
			!currentPageName ||
			(currentBreadcrumbs.includes(currentPageName) && currentPageName === currentBreadcrumbs.slice(-1)[0])
		) {
			return;
		}

		if (currentBreadcrumbs.includes(currentPageName)) {
			currentBreadcrumbs.splice(currentBreadcrumbs.indexOf(currentPageName) + 1);
		} else {
			currentBreadcrumbs.push(currentPageName);
		}
		setCurrentBreadcrumbs([...currentBreadcrumbs]);
	}, [currentBreadcrumbs, currentPage, setCurrentBreadcrumbs]);

	return (
		<div className={BreadcrumbsClass}>
			<ul>
				{isClient &&
					currentBreadcrumbs.map((item, index, breadcrumbs) => {
						if (breadcrumbs.length - 2 === index) {
							return (
								<li key={item}>
									<button
										type="button"
										role="link"
										onClick={() => {
											return router.back();
										}}
									>
										{item}
									</button>
								</li>
							);
						}
						return <li key={item}>{item}</li>;
					})}
			</ul>
		</div>
	);
}
