import { useEffect } from "react";

import { createGlobalState, useLocalStorage } from "react-use";

import Consent from "@/lib/consent";

const consentStorageKey = "consent-accepted";
const useGlobalValue = createGlobalState([]);

export default function useConsent(token = null) {
	const [consentAcceptedStore, setConsentAcceptedStore] = useLocalStorage(consentStorageKey, []);
	const [, setAcceptedConsent] = useGlobalValue();

	useEffect(() => {
		if (token && !consentAcceptedStore.length) {
			(async () => {
				const consentData = await Consent(token);
				if (!consentAcceptedStore?.length && consentData?.length) {
					setConsentAcceptedStore(consentData);
				}
			})();
		}
	}, [token]);

	useEffect(() => {
		setAcceptedConsent(consentAcceptedStore);

		(async () => {
			if (token) {
				await Consent(token, consentAcceptedStore);
			}
		})();
	}, [consentAcceptedStore]);

	return [consentAcceptedStore, setConsentAcceptedStore];
}

export function useAcceptedConsent() {
	const [acceptedConsent, setAcceptedConsent] = useGlobalValue();

	useEffect(() => {
		const fromStorage = JSON.parse(window.localStorage.getItem(consentStorageKey));
		if (!acceptedConsent.length && fromStorage?.length) {
			setAcceptedConsent(fromStorage ?? []);
		}
	});

	return acceptedConsent;
}
