import Image from "next/image";

import LogoLJ from "@/assets/logos/LJ.svg";
import LogoOranum from "@/assets/logos/Oranum.svg";
import { productLogo } from "@/styles/Blocks.module.scss";

export default function ProductLogo({ product }) {
	let productLogoSrc;
	// eslint-disable-next-line default-case
	switch (product) {
		case "livejasmin":
			productLogoSrc = LogoLJ;
			break;
		case "oranum":
			productLogoSrc = LogoOranum;
			break;
	}
	return (
		productLogoSrc && (
			<picture className={productLogo}>
				<Image src={productLogoSrc} alt="Product Logo" height={24} />
			</picture>
		)
	);
}
