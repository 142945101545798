import { useEffect, useLayoutEffect, useState } from "react";

import { useRouter } from "next/router";

import AuthenticationError from "@/components/AuthenticationError";
import Breadcrumbs from "@/components/Breadcrumbs";
import CookieConsent from "@/components/CookieConsent";
import Footer from "@/components/Footer";
import Header from "@/components/Header";
import Loader from "@/components/Loader";
import ProductLogo from "@/components/ProductLogo";
import useAuth from "@/hooks/useAuth";
import useConsent from "@/hooks/useConsent";

const noAuth = [
	"/",
	"/erasure/email-verification",
	"/erasure/good-bye",
	"/erasure/revoke",
	"/logout",
	"/verification/[site]/decline",
	"/verification/[site]/accept",
	"/accounts/[site]",
];

export default function AuthLayout({ Component }) {
	const { replace, pathname, query } = useRouter();
	const authData = useAuth();
	const { isLoading, user, isError, product, token, setProduct } = authData;
	const [consentAccepted, setConsentAccepted] = useConsent(token);
	const [showCookieConsent, setShowCookieConsent] = useState(false);

	const currentPage = Component?.pageName ?? null;
	const breadcrumbs = Component?.breadcrumbs ?? true;

	useEffect(() => {
		if (!product && ["oranum", "livejasmin"].includes(query?.product)) {
			setProduct(query.product);
		}
	}, [product, query.product, setProduct]);

	useLayoutEffect(() => {
		(async () => {
			if (!(noAuth.includes(pathname) || isLoading || user)) {
				await replace("/404");
			}
		})();
	});

	useEffect(() => {
		if (query?.emptyConsent) {
			setConsentAccepted([]);
			replace(pathname, undefined, { shallow: true });
		}
	}, [pathname, query, replace, setConsentAccepted]);

	useEffect(() => {
		setShowCookieConsent(setConsentAccepted && !consentAccepted?.includes("essential"));
	}, [consentAccepted, setConsentAccepted]);

	let content = <Component {...authData} />;

	if (isError) {
		content = <AuthenticationError />;
	} else if (isLoading) {
		content = <Loader />;
	}

	return (
		<>
			{showCookieConsent && <CookieConsent consentAccepted={consentAccepted} setConsentAccepted={setConsentAccepted} />}
			<div className="main-container">
				<Header user={user} product={product} />
				<main className="main-content">
					{breadcrumbs && <Breadcrumbs currentPage={currentPage} />}
					<ProductLogo product={product} />
					{content}
				</main>
				<Footer />
			</div>
		</>
	);
}
