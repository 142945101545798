import { useCallback, useState } from "react";

import Head from "next/head";
import Script from "next/script";
import { useEvent } from "react-use";

import favicon16Dark from "@/assets/favicons/favicon-16x16-dark.png";
import favicon16 from "@/assets/favicons/favicon-16x16.png";
import favicon32Dark from "@/assets/favicons/favicon-32x32-dark.png";
import favicon32 from "@/assets/favicons/favicon-32x32.png";
import faviconDark from "@/assets/favicons/favicon-dark.ico";
import favicon from "@/assets/favicons/favicon.ico";
import { useAcceptedConsent } from "@/hooks/useConsent";
import useTranslation from "@/hooks/useTranslation";
import AuthLayout from "@/pages/_authLayout";
import StandaloneLayout from "@/pages/_standaloneLayout";
import { Offline } from "@/styles/Blocks.module.scss";

export default function MainLayout({ Component }) {
	const [isOnline, setIsOnline] = useState(true);
	const acceptedConsent = useAcceptedConsent();
	const { _ } = useTranslation();

	const handleOffline = useCallback(() => {
		setIsOnline(false);
	}, []);

	const handleOnline = useCallback(() => {
		setIsOnline(true);
	}, []);

	useEvent("offline", handleOffline);
	useEvent("online", handleOnline);

	const ChildLayout = Component?.cookieConsent === false ? StandaloneLayout : AuthLayout;

	return (
		<>
			<Head>
				<title>JWS Privacy</title>
				<meta
					name="viewport"
					content="width=device-width, initial-scale=1.0, maximum-scale=1.0, minimum-scale=1.0, user-scalable=no, viewport-fit=cover"
				/>
				<link rel="icon" type="image/x-icon" sizes="16x16" href={favicon.src} media="(prefers-color-scheme: light)" />
				<link rel="icon" type="image/png" sizes="32x32" href={favicon32.src} media="(prefers-color-scheme: light)" />
				<link rel="icon" type="image/png" sizes="16x16" href={favicon16.src} media="(prefers-color-scheme: light)" />
				<link
					rel="icon"
					type="image/x-icon"
					sizes="16x16"
					href={faviconDark.src}
					media="(prefers-color-scheme: dark)"
				/>
				<link rel="icon" type="image/png" sizes="32x32" href={favicon32Dark.src} media="(prefers-color-scheme: dark)" />
				<link rel="icon" type="image/png" sizes="16x16" href={favicon16Dark.src} media="(prefers-color-scheme: dark)" />
			</Head>
			{acceptedConsent && acceptedConsent.includes("analytical") && (
				<Script id="google-tag-manager" strategy="afterInteractive">
					{`(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
						new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
						j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.defer=true;j.src=
						'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
					})(window,document,'script','dataLayer','${process.env.NEXT_PUBLIC_GTM_CODE}');`}
				</Script>
			)}
			<div className="main-container">
				{!isOnline && (
					<div className={Offline}>
						<h3>{_("No internet access!")}</h3>
						<p>{_("Please check your internet connection and try again.")}</p>
					</div>
				)}
				<ChildLayout Component={Component} />
			</div>
		</>
	);
}
